<template>
  <baidu-map class="map" :center="position" :zoom="zoom">
    <bm-marker :position="position" :dragging="true" @click="infoWindowOpen">
      <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
        设备编号：{{editForm.shebeiCode}}<br>
        设备名称：{{editForm.shebeiName}}
      </bm-info-window>
    </bm-marker>
    <button @click="zoomIn">+</button>
    <button @click="zoomOut">-</button>
  </baidu-map>
</template>

<script>
export default {
  name: "BdMapPosition",
  data () {
    return {
      show: false,
      position:{lng: 116.404, lat: 39.915},
      zoom: 15,
      miaoshu:"",
    }
  },
  props: {
    editForm:{
      type:Object,
      default:()=>{}
    },
  },
  created() {

  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },
    zoomIn() {
      this.zoom = this.zoom + 1;
    },
    zoomOut() {
      this.zoom = this.zoom - 1;
    }
  }
}
</script>

<style>
.map {
  width: 100%;
  height: 600px;
}
</style>