<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-input style="width: 150px;margin-right: 5px"
						v-model="searchForm.shebeiCode"
						placeholder="设备编号"
						clearable
				>
				</el-input>
        <el-input style="width: 150px;margin-right: 5px"
            v-model="searchForm.shebeiName"
            placeholder="设备名称"
            clearable
        >
        </el-input>
        <el-input style="width: 150px;"
            v-model="searchForm.shebeiType"
            placeholder="设备型号"
            clearable
        >
        </el-input>
			</el-form-item>

			<el-form-item>
				<el-button @click="getShebeiList">搜索</el-button>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="dialogVisible = true" v-if="hasAuth('sys:device:save')">新增</el-button>
			</el-form-item>
			<el-form-item>
				<el-popconfirm title="确定批量删除吗？" @confirm="delHandle(null)">
					<el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:device:delete')">批量删除</el-button>
				</el-popconfirm>
			</el-form-item>
		</el-form>

		<el-table
				ref="multipleTable"
				:data="tableData"
				tooltip-effect="dark"
				style="width: 100%"
				border
				stripe
				@selection-change="handleSelectionChange">

			<el-table-column
					type="selection"
					width="55">
			</el-table-column>

      <el-table-column
          label="设备图片"
          width="120">
        <template slot-scope="scope">
          <el-image v-if="scope.row.shebeiPic" size="small" :src="scope.row.shebeiPic" :preview-src-list="[scope.row.shebeiPic]"></el-image>
        </template>
      </el-table-column>

      <el-table-column
          prop="shebeiCode"
          label="设备编号"
          width="120">
      </el-table-column>

			<el-table-column
					prop="shebeiName"
					label="设备名称"
					width="120">
			</el-table-column>

			<el-table-column
					prop="shebeiType"
					label="设备型号"
          width="120">
			</el-table-column>

			<el-table-column
					prop="glxh"
					label="功率消耗（kw）"
          width="130">
			</el-table-column>

			<el-table-column
					prop="createtime"
					label="生产日期">
			</el-table-column>

			<el-table-column
					prop="statu"
					label="状态">
				<template slot-scope="scope">
					<el-tag size="small" v-if="scope.row.statu === 1" type="success">正常</el-tag>
					<el-tag size="small" v-else-if="scope.row.statu === 0" type="danger">禁用</el-tag>
				</template>

			</el-table-column>
			<el-table-column
					prop="created"
					width="200"
					label="创建时间"
			>
			</el-table-column>
			<el-table-column
					prop="icon"
					width="420px"
					label="操作">

				<template slot-scope="scope">
          <el-button type="text" @click="eimageHandle(scope.row.id)" v-if="hasAuth('sys:device:image')">设备图片</el-button>
          <el-divider direction="vertical"></el-divider>

					<el-button type="text" @click="editHandle(scope.row.id)" v-if="hasAuth('sys:device:update')">编辑</el-button>
					<el-divider direction="vertical"></el-divider>

          <el-button type="text" @click="editXiangqing(scope.row.id)" v-if="hasAuth('sys:device:detail')">详情</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="madeWxCode(scope.row.id)" v-if="hasAuth('sys:device:wxcode')">生成二维码</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="enterBdMapPosition(scope.row.id)" v-if="hasAuth('sys:device:locat')">设备定位</el-button>
          <el-divider direction="vertical"></el-divider>
					<template>
						<el-popconfirm title="确定删除吗？" @confirm="delHandle(scope.row.id)">
							<el-button type="text" slot="reference" v-if="hasAuth('sys:device:delete')">删除</el-button>
						</el-popconfirm>
					</template>

				</template>
			</el-table-column>

		</el-table>

		<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				layout="total, sizes, prev, pager, next, jumper"
				:page-sizes="[10, 20, 50, 100]"
				:current-page="current"
				:page-size="size"
				:total="total">
		</el-pagination>


		<!--新增对话框-->
		<el-dialog
				title="设备信息"
				:visible.sync="dialogVisible"
				width="900px"
				:before-close="handleClose">

			<el-form :model="editForm" :rules="editFormShebei" ref="editForm">
        <div style="float: left;">
          <el-form-item label="设备编号" prop="shebeiCode" label-width="120px">
            <el-input v-model="editForm.shebeiCode" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="设备名称"  prop="shebeiName" label-width="120px">
            <el-input v-model="editForm.shebeiName" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="设备型号" prop="shebeiType" label-width="120px">
            <el-input v-model="editForm.shebeiType" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="功率（KW）"  prop="glxh" label-width="120px">
            <el-input v-model="editForm.glxh" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="额定电压（V）" prop="eddy" label-width="120px">
            <el-input v-model="editForm.eddy" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="额定电流（W）"  prop="eddl" label-width="120px">
            <el-input v-model="editForm.eddl" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="设备用途" prop="shebeiYt" label-width="120px">
            <el-input v-model="editForm.shebeiYt" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="设备重量（KG）"  prop="shebeiZl" label-width="120px">
            <el-input v-model="editForm.shebeiZl" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="生产日期" prop="createtime" label-width="120px">
            <template>
              <div class="block" style="width: 280px;">
                <el-date-picker
                    v-model="editForm.createtime"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="状态"  prop="statu" label-width="120px">
            <el-radio-group v-model="editForm.statu" style="width: 280px;margin-right: 50px">
              <el-radio :label="0">禁用</el-radio>
              <el-radio :label="1">正常</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
			</el-form>
			<div slot="footer" class="dialog-footer" >
				<el-button @click="resetForm('editForm')">取 消</el-button>
				<el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
			</div>
		</el-dialog>

    <xiangqing v-model:visible="visible" @getShebeiList="getShebeiList"  v-bind:editForm="editForm"></xiangqing>
<!--    <el-dialog
        title="详情"
        :visible.sync="visible"
        width="900px"
        :before-close="close">

      <el-form :model="editForm" :rules="editFormShebei" ref="editForm" style="height: 400px">
        <div style="float: left;">
          <el-form-item label="设备编号" prop="shebeiCode" label-width="120px">
            <el-input v-model="editForm.shebeiCode" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="设备名称"  prop="shebeiName" label-width="120px">
            <el-input v-model="editForm.shebeiName" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="设备型号" prop="shebeiType" label-width="120px">
            <el-input v-model="editForm.shebeiType" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="功率（KW）"  prop="glxh" label-width="120px">
            <el-input v-model="editForm.glxh" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="额定电压（V）" prop="eddy" label-width="120px">
            <el-input v-model="editForm.eddy" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="额定电流（W）"  prop="eddl" label-width="120px">
            <el-input v-model="editForm.eddl" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="设备用途" prop="shebeiYt" label-width="120px">
            <el-input v-model="editForm.shebeiYt" autocomplete="off" style="width: 280px;"></el-input>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="设备重量（KG）"  prop="shebeiZl" label-width="120px">
            <el-input v-model="editForm.shebeiZl" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
          </el-form-item>
        </div>
        <div style="float: left;">
          <el-form-item label="生产日期" prop="createtime" label-width="120px">
            <template>
              <div class="block" style="width: 280px;">
                <el-date-picker
                    v-model="editForm.createtime"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </el-form-item>
        </div>
        <div style="float: right;">
          <el-form-item label="状态"  prop="statu" label-width="120px">
            <el-radio-group v-model="editForm.statu" style="width: 280px;margin-right: 50px">
              <el-radio :label="0">禁用</el-radio>
              <el-radio :label="1">正常</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" >

      </div>
    </el-dialog>-->

    <el-dialog
        title="二维码"
        :visible.sync="wxcodeVisible"
        width="500px"
        :before-close="wxcodeClose">
      <el-form :model="editForm" :rules="editFormShebei" ref="editForm" style="height: 400px">
        <div class="image-container" style="height: 400px;">
          <img :src="base64String" alt="" style="width: 300px;border:1px solid #f2f2f2">
        </div>
        <div style="width: 100%;text-align: center;">
          <span class="demonstration">{{ wxCodeName }}</span>
        </div>

      </el-form>

      <div slot="footer" class="dialog-footer" >
        <el-button @click="resetForm3('editForm')">取 消</el-button>
        <el-button type="primary" @click="exportCode(editForm.id)">下载</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="设备图片"
        :visible.sync="imageVisible"
        width="500px"
        :before-close="imageClose">
      <el-form :model="editForm" :rules="editFormShebei" ref="editForm" style="height: 400px;text-align: center">
        <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :http-request="upload"
            :multiple="false"
            :limit="1"
            :file-list="fileList"
            :auto-upload="false">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
          <div class="demo-image__preview">
            <el-image  width="100%" :src="imgUrl" :preview-src-list="sbimgList" alt="" style="margin-top: 20px;"></el-image>
          </div>
        </el-upload>
      </el-form>

      <div slot="footer" class="dialog-footer" >
        <el-button @click="resetForm4('editForm')">取 消</el-button>
        <el-button type="primary" @click="saveShebeiPic(editForm.id)">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="设备定位"
        :visible.sync="Positionvisible"
        width="800px"
        :before-close="PositionClose"
        >
      <BdMapPosition v-bind:editForm="editForm"></BdMapPosition>

      <div slot="footer" class="dialog-footer" >
        <el-button @click="resetFormPosition()">取 消</el-button>
      </div>
    </el-dialog>

	</div>

</template>

<script>
import xiangqing from "./xiangqing.vue";
import BdMapPosition from "./BdMapPosition.vue";
import draggable from "@/views/utils/draggable";
	export default {
		name: "Shebei",
    components: {'xiangqing':xiangqing,"BdMapPosition":BdMapPosition},
    directives: {draggable},
		data() {
			return {
				searchForm: {},
				delBtlStatu: true,

				total: 0,
				size: 10,
				current: 1,

        visible:false,
				dialogVisible: false,
        wxcodeVisible: false,
        imageVisible: false,
        Positionvisible :false,

        base64String: '', // Base64字符串
				editForm: {

				},
        dialogTitle: "详情",
				tableData: [],
        imgUrl: '',
        sbimgList: [],
        fileList: [],
        wxCodeName: '',

        editFormShebei: {
					shebeiCode: [
						{required: true, message: '请输入设备编号', trigger: 'blur'}
					],
					shebeiName: [
						{required: true, message: '请输入设备名称', trigger: 'blur'}
					],
					shebeiType: [
						{required: true, message: '请选择设备型号', trigger: 'blur'}
					],
          glxh: [
            {required: true, message: '请输入设备功耗', trigger: 'blur'}
          ],
          eddy: [
            {required: true, message: '请输入设备额定电压', trigger: 'blur'}
          ],
          eddl: [
            {required: true, message: '请选择设备额定电流', trigger: 'blur'}
          ],
          createtime: [
            {required: true, message: '请选择设备生产日期', trigger: 'blur'}
          ]

				},

				multipleSelection: [],

				roleDialogFormVisible: false,
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				roleForm: {},
				roleTreeData:  [],
				treeCheckedKeys: [],
				checkStrictly: true,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        }
			}
		},
		created() {
			this.getShebeiList();
		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				console.log("勾选")
				console.log(val)
				this.multipleSelection = val;

				this.delBtlStatu = val.length == 0
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.size = val
				this.getShebeiList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.current = val
				this.getShebeiList()
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisible = false
				this.editForm = {}
			},
      resetForm2(formName) {
        this.$refs[formName].resetFields();
        this.visible = false
        this.editForm = {}
      },
      resetForm3(formName) {
        this.$refs[formName].resetFields();
        this.wxcodeVisible = false
        this.editForm = {}
      },
      resetForm4(formName) {
        this.$refs[formName].resetFields();
        this.imageVisible = false
        this.editForm = {}
      },
      resetFormPosition(formName){
        this.Positionvisible = false
        this.editForm = {}
      },
			handleClose() {
				this.resetForm('editForm')
			},
      close() {
        this.resetForm2('editForm')
      },
      wxcodeClose() {
        this.resetForm3('editForm')
      },
      imageClose() {
        this.resetForm4('editForm')
        this.fileList = [];
        this.imgUrl = '';
      },
      PositionClose () {
        this.resetFormPosition('editForm')
      },
      getShebeiList() {
				this.$axios.get("/sys/device/list", {
					params: {
            shebeiCode: this.searchForm.shebeiCode,
            shebeiName: this.searchForm.shebeiName,
            shebeiType: this.searchForm.shebeiType,
						current: this.current,
						size: this.size
					}
				}).then(res => {
					this.tableData = res.data.data.records
					this.size = res.data.data.size
					this.current = res.data.data.current
					this.total = res.data.data.total
				})
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/sys/device/' + (this.editForm.id?'update' : 'save'), this.editForm)
							.then(res => {
                this.getShebeiList()
                this.resetForm('editForm')
								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success',
									onClose:() => {
									}
								});

								this.dialogVisible = false
							})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			editHandle(id) {
				this.$axios.get('/sys/device/info/' + id).then(res => {
					this.editForm = res.data.data
					this.dialogVisible = true
				})
			},
      editXiangqing(id){
        this.$axios.get('/sys/device/info/' + id).then(res => {
          this.editForm = res.data.data

          this.visible=true
        })
      },
      madeWxCode(id) {
        this.$axios.get('/sys/device/madeWxCode/' + id).then(res => {
          this.editForm = res.data.data
          // base64编码的图片
          this.base64String = "data:image/png;base64,"+res.data.data.wxcode;
          this.wxCodeName = res.data.data.shebeiCode
          this.wxcodeVisible=true
        })
      },
      enterBdMapPosition(id) {
        this.$axios.get('/sys/device/info/' + id).then(res => {
          this.editForm = res.data.data

          this.Positionvisible=true
        })
      },
      eimageHandle(id) {
        this.$axios.get('/sys/device/info/' + id).then(res => {
          this.editForm = res.data.data
          this.imgUrl = res.data.data.shebeiPic
          this.sbimgList = [res.data.data.shebeiPic]
          this.imageVisible=true
        })
      },
      exportCode(id) {
        this.$axios.get('/sys/device/info/' + id).then(res => {
          const imgUrl = this.base64String;
          // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
          if (window.navigator.msSaveOrOpenBlob) {
            const bstr = atob(imgUrl.split(',')[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, res.data.data.shebeiCode + '.jpg');
          } else {
            // 这里就按照chrome等新版浏览器来处理
            const a = document.createElement('a');
            a.href = imgUrl;
            a.setAttribute('download', res.data.data.shebeiCode + '.jpg');
            a.click();
          }
        })
      },
      saveShebeiPic(id) {
        if (this.imgUrl==null||this.imgUrl=='') {
          alert("请先上传图片！");
          return;
        }
        this.$axios.post('/sys/device/saveImage/',
        {
          'id': id,
          'shebeiPic': this.imgUrl
        }).then(res => {
          this.getShebeiList()
          this.imageVisible=false
          this.resetForm4('editForm')
          this.$message({
            showClose: true,
            message: '操作成功',
            type: 'success',
            onClose: () => {

            }
          });
        })
      },

			delHandle(id) {
				var ids = []
				if (id) {
					ids.push(id)
				} else {
					this.multipleSelection.forEach(row => {
						ids.push(row.id)
					})
				}

				console.log(ids)

				this.$axios.post("/sys/device/delete", ids).then(res => {
          this.getShebeiList()
					this.$message({
						showClose: true,
						message: '操作成功',
						type: 'success',
						onClose:() => {

						}
					});
				})
			},

      submitUpload () {
        this.$refs.upload.submit()
      },
      upload (file) {
        const formdata = new FormData()
        // 上传图片并返回路径
        formdata.append('image', file.file)

        this.$axios.post('/sys/device/uploadImage', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }}).then((resp) => {
          if (resp.status === 200) {
            console.log(resp.data)
            // 设置图片回显
            this.imgUrl = resp.data.data
            this.sbimgList = [resp.data.data]
            this.fileList = [];
            this.$message({type: 'success', message: '图片上传成功！'})
          }
        }).catch((e) => {
          console.log(e)
          this.$message({type: 'info', message: e+'图片上传失败！'})
        })
      },
		}
  }
</script>

<style scoped>

	.el-pagination {
		float: right;
		margin-top: 22px;
	}
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* 设置容器高度 */
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>