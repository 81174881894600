<template>
  <div>
  <el-dialog
      :visible.sync="dialogVisible"
      title="详情"
      width="900px"
      :before-close="close">

    <el-form :model="editForm" ref="editForm" style="height: 400px">
      <div style="float: left;">
        <el-form-item label="设备编号" prop="shebeiCode" label-width="120px">
          <el-input v-model="editForm.shebeiCode" autocomplete="off" style="width: 280px;"></el-input>
        </el-form-item>
      </div>
      <div style="float: right;">
        <el-form-item label="设备名称"  prop="shebeiName" label-width="120px">
          <el-input v-model="editForm.shebeiName" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
        </el-form-item>
      </div>
      <div style="float: left;">
        <el-form-item label="设备型号" prop="shebeiType" label-width="120px">
          <el-input v-model="editForm.shebeiType" autocomplete="off" style="width: 280px;"></el-input>
        </el-form-item>
      </div>
      <div style="float: right;">
        <el-form-item label="功率（KW）"  prop="glxh" label-width="120px">
          <el-input v-model="editForm.glxh" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
        </el-form-item>
      </div>
      <div style="float: left;">
        <el-form-item label="额定电压（V）" prop="eddy" label-width="120px">
          <el-input v-model="editForm.eddy" autocomplete="off" style="width: 280px;"></el-input>
        </el-form-item>
      </div>
      <div style="float: right;">
        <el-form-item label="额定电流（W）"  prop="eddl" label-width="120px">
          <el-input v-model="editForm.eddl" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
        </el-form-item>
      </div>
      <div style="float: left;">
        <el-form-item label="设备用途" prop="shebeiYt" label-width="120px">
          <el-input v-model="editForm.shebeiYt" autocomplete="off" style="width: 280px;"></el-input>
        </el-form-item>
      </div>
      <div style="float: right;">
        <el-form-item label="设备重量（KG）"  prop="shebeiZl" label-width="120px">
          <el-input v-model="editForm.shebeiZl" autocomplete="off" style="width: 280px;margin-right: 50px"></el-input>
        </el-form-item>
      </div>
      <div style="float: left;">
        <el-form-item label="生产日期" prop="createtime" label-width="120px">
          <template>
            <div class="block" style="width: 280px;">
              <el-date-picker
                  v-model="editForm.createtime"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </template>
        </el-form-item>
      </div>
      <div style="float: right;">
        <el-form-item label="状态"  prop="statu" label-width="120px">
          <el-radio-group v-model="editForm.statu" style="width: 280px;margin-right: 50px">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer" >
    </div>
  </el-dialog>
  </div>
</template>

<script>
export default {
  name: "xiangqing",
  data() {
    return {
    }
  },
  props: {
    value: {
      //控制弹窗打开
      type: Boolean,
      required: true
    },

    editForm:{
      type:Object,
      default:()=>{}
    },
  },
  computed: {
    dialogVisible: {	// 这里通过 computed 方法 来定义  变量 dialogVisible
      get: function () {	// 初始化 dialogVisible 的值
        return this.value
      },
      set: function (val) {	// 当 dialogVisible 需要赋值时，直接改变 父组件中的值
        this.$emit('input', val)
      }
    }
  },
  methods: {
    close(){
        this.dialogVisible=false
    },
  }
}
</script>
